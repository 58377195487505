import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OccurrenceDetails from './OccurrenceDetails/OccurrenceDetails.react';
import {
  Wrapper,
  Text,
  AddOccurrenceLabel,
  Info,
  DialogActionStyle,
} from './DeliverDetails.style';
import {
  DELIVERY_DETAILS,
  initialDeliveryDetailState,
  setRowDisabled,
} from './DeliveryDetails.content';
import { ReactComponent as AddIcon } from '../../assets/icons/addicon.svg';
import { uniqueId } from 'utils/uniqueIdGenerator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { itcToDateFormatter } from 'utils/dateFormatter';
import { RED } from 'theme/GlobalColors';
import { GREEN } from 'utils/config';
import axios from 'axios';
import { DELIVERY_DETAILS_SUBMIT } from 'urls';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import { closeIconSx } from 'theme/GlobalStyles';

const DeliveryDetailsModal = ({
  viewMode,
  viewModeTitle,
  title,
  isOpen = false,
  onCancel,
  acceptCtnLabel,
  declineCtnLabel,
  creditsAvailable,
  setProjectDeliveryDetails,
  setProjectPastOccurrenceDetails,
  hasRadioButton,
  setIsDeliveryDetailsOpen,
  isEditable,
  clearDeliveryDetails,
  pastOccurrences,
  activeOccurrencesDetails,
  deliveryDetailsForSale,
  hasPastOccurrences,
  deliveryDetailList,
  minDeliveryDate,
  projectObj,
  vintages,
  editMode,
  isModalWarningDialogOpen,
  isLinkedToSale,
}) => {
  const [deliveryDetails, setDeliveryDetails] = useState(
    activeOccurrencesDetails.length !== 0
      ? activeOccurrencesDetails
      : initialDeliveryDetailState(deliveryDetailsForSale),
  );
  const [pastOccurrenceDetail, setPastOccurrenceDetail] =
    useState(pastOccurrences);
  const [date, setDate] = useState(new Date());
  const [mtAmount, setMtAmount] = useState();
  const [radioButtonValue, setRadioButtonValue] = useState();
  const [creditSum, setCreditSum] = useState();
  const [isCreditValueExceed, setIsCreditValueExceeded] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [showPastOccurrence, setShowPastOccurrence] = useState(false);
  const [formError, setFormError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [rowValidationError, setRowValidationError] = useState(false);
  const [amountvalidate, setAmountValidate] = useState({
    maxAmount: '',
    rowError: false,
  });
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);

  useEffect(() => {
    setFormError(false);
    setValidationError(false);
    setIsCreditValueExceeded(false);
    if (
      clearDeliveryDetails === true &&
      activeOccurrencesDetails.length === 0 &&
      pastOccurrences.length === 0
    ) {
      setDeliveryDetailsData();
    } else if (
      clearDeliveryDetails === true &&
      (activeOccurrencesDetails.length > 0 || pastOccurrences.length > 0)
    ) {
      if (activeOccurrencesDetails.length === 0) {
        setDeliveryDetails(initialDeliveryDetailState(deliveryDetailsForSale));
      } else {
        setDeliveryDetails(activeOccurrencesDetails);
      }
      setPastOccurrenceDetail(pastOccurrences);
      setRowValidationError(false);
      setAmountValidate({ rowError: false, maxAmount: '' });
    }
  }, [clearDeliveryDetails]);

  const setDeliveryDetailsData = () => {
    if (deliveryDetailList.length > 0) {
      setDeliveryDetails(
        deliveryDetailList.map((details) => ({
          id: uniqueId(),
          scheduleId: details.scheduleId,
          occursOn: details.deliveryDate,
          mtAmount: deliveryDetailsForSale
            ? details.allocatedSupply
            : details.projectedCreditDelivery,
          deliveryStatus: deliveryDetailsForSale
            ? details.deliveryStatus
            : DELIVERY_DETAILS.deposited,
          rowValid: true,
          isApiPopulated: false,
        })),
      );
    } else if (deliveryDetailList.length === 0) {
      setDeliveryDetails([
        {
          id: uniqueId(),
          scheduleId: 0,
          occursOn: null,
          mtAmount: '',
          deliveryStatus: deliveryDetailsForSale
            ? ''
            : DELIVERY_DETAILS.deposited,
          rowValid: !deliveryDetailsForSale,
          isApiPopulated: false,
        },
      ]);
    }
    setRowValidationError(false);
    setAmountValidate({ rowError: false, maxAmount: '' });
  };

  const handleClickAddOccurrence = () => {
    setDate(new Date());
    setMtAmount(null);
    setRadioButtonValue(null);
    setAmountValidate({ rowError: false, maxAmount: '' });
    setDeliveryDetails([
      ...deliveryDetails,
      {
        id: uniqueId(),
        scheduleId: 0,
        occursOn: null,
        mtAmount: '',
        deliveryStatus: deliveryDetailsForSale
          ? ''
          : DELIVERY_DETAILS.deposited,
        rowValid: !deliveryDetailsForSale,
        isApiPopulated: false,
      },
    ]);
  };

  const validateForm = () => {
    const finalDeliveryDetails =
      pastOccurrenceDetail?.length > 0
        ? deliveryDetails.concat(pastOccurrenceDetail)
        : deliveryDetails;
    const invalidItem = finalDeliveryDetails.filter(
      (item) =>
        item.mtAmount === '' ||
        item.occursOn === null ||
        item.deliveryStatus === '',
    );

    if (invalidItem.length > 0) {
      setFormError(true);
      setIsConfirmDisabled(true);
    } else {
      handleFormSubmit();
    }
  };
  const checkTernaryCondition = (item, condition1, conditon2) => {
    return item ? condition1 : conditon2;
  };
  const handleSetDeliveryDetail = () => {
    setProjectDeliveryDetails(
      deliveryDetails.map((item) => {
        if (!deliveryDetailsForSale) {
          return {
            scheduleId: item.scheduleId,
            projectedCreditDelivery: item.mtAmount,
            deliveryDate: itcToDateFormatter(item.occursOn),
            deliveryStatus: checkTernaryCondition(
              deliveryDetailsForSale,
              item.deliveryStatus,
              DELIVERY_DETAILS.deposited,
            ),
            isApiPopulated: item.isApiPopulated,
          };
        } else {
          return {
            scheduleId: item.scheduleId,
            allocatedSupply: item.mtAmount,
            deliveryDate: itcToDateFormatter(item.occursOn),
            deliveryStatus: checkTernaryCondition(
              deliveryDetailsForSale,
              item.deliveryStatus,
              DELIVERY_DETAILS.deposited,
            ),
            isApiPopulated: item.isApiPopulated,
          };
        }
      }),
    );
    if (pastOccurrenceDetail?.length > 0) {
      setProjectPastOccurrenceDetails(
        pastOccurrenceDetail.map((item) => {
          if (deliveryDetailsForSale) {
            return {
              scheduleId: item.scheduleId,
              allocatedSupply: item.mtAmount,
              deliveryDate: itcToDateFormatter(item.occursOn),
              deliveryStatus: checkTernaryCondition(
                deliveryDetailsForSale,
                item.deliveryStatus,
                DELIVERY_DETAILS.deposited,
              ),
              isApiPopulated: item.isApiPopulated,
            };
          } else {
            return {
              scheduleId: item.scheduleId,
              projectedCreditDelivery: item.mtAmount,
              deliveryDate: itcToDateFormatter(item.occursOn),
              deliveryStatus: checkTernaryCondition(
                deliveryDetailsForSale,
                item.deliveryStatus,
                DELIVERY_DETAILS.deposited,
              ),
              isApiPopulated: item.isApiPopulated,
            };
          }
        }),
      );
    }
  };

  const handleFormSubmit = () => {
    setFormError(false);
    if (deliveryDetailsForSale) {
      setIsConfirmDisabled(true);
      axios
        .post(DELIVERY_DETAILS_SUBMIT, {
          internalProjectId: projectObj.projectName.internalProjectId,
          vintageStart: vintages.vintageStart,
          vintageEnd: vintages.vintageEnd,
          deliveryDetails: deliveryDetails
            .filter((detail) => !detail.isApiPopulated)
            .map((item) => ({
              allocatedSupply: item.mtAmount,
              deliveryDate: itcToDateFormatter(item.occursOn),
              deliveryStatus: deliveryDetailsForSale
                ? item.deliveryStatus
                : DELIVERY_DETAILS.deposited,
            }))
            .concat(
              pastOccurrenceDetail?.length > 0
                ? pastOccurrenceDetail
                    .filter((detail) => !detail.isApiPopulated)
                    .map((item) => ({
                      allocatedSupply: item.mtAmount,
                      deliveryDate: itcToDateFormatter(item.occursOn),
                      deliveryStatus: deliveryDetailsForSale
                        ? item.deliveryStatus
                        : DELIVERY_DETAILS.deposited,
                    }))
                : [],
            ),
        })
        .then((response) => {
          if (response.data.valid) {
            handleSetDeliveryDetail();
          }
          setValidationError(!response.data.valid);
          setIsDeliveryDetailsOpen(!response.data.valid);
          setIsConfirmDisabled(!response.data.valid);
        });
    } else {
      handleSetDeliveryDetail();
      setIsDeliveryDetailsOpen(false);
    }
  };

  const checkCreditsAvailable = (finalSum, creditsAvailable) => {
    return finalSum > 0 && creditsAvailable > 0;
  };

  useEffect(() => {
    const sumOfMtCo2 = deliveryDetails.reduce((accumulator, object) => {
      return accumulator + +object.mtAmount;
    }, 0);
    let sumOfMtCo2PastOccurrence = 0;
    if (pastOccurrenceDetail?.length > 0) {
      sumOfMtCo2PastOccurrence = pastOccurrenceDetail.reduce(
        (accumulator, object) => {
          return accumulator + +object.mtAmount;
        },
        0,
      );
    }
    let finalSum = sumOfMtCo2 + sumOfMtCo2PastOccurrence;
    setCreditSum(finalSum.toFixed(2));
    if (parseFloat(finalSum) !== parseFloat(creditsAvailable)) {
      setIsConfirmDisabled(true);
      if (checkCreditsAvailable(finalSum, creditsAvailable)) {
        setIsCreditValueExceeded(true);
      }
    } else {
      setIsCreditValueExceeded(false);
      setIsConfirmDisabled(false);
    }
  }, [mtAmount, deliveryDetails, pastOccurrenceDetail, creditsAvailable]);

  useEffect(() => {
    const invalidItem = deliveryDetails.filter(
      (item) =>
        item.mtAmount === '' ||
        item.occursOn === null ||
        item.deliveryStatus === '',
    );
    if (invalidItem.length === 0) {
      setFormError(false);
    }
  }, [deliveryDetails]);

  useEffect(() => {
    if (
      clearDeliveryDetails === false &&
      isOpen === true &&
      isModalWarningDialogOpen &&
      activeOccurrencesDetails.length === 0 &&
      pastOccurrences.length === 0
    ) {
      setDeliveryDetailsData();
    } else if (
      clearDeliveryDetails === false &&
      isOpen === true &&
      isModalWarningDialogOpen &&
      (activeOccurrencesDetails.length > 0 || pastOccurrences.length > 0)
    ) {
      if (activeOccurrencesDetails.length === 0) {
        setDeliveryDetails(initialDeliveryDetailState(deliveryDetailsForSale));
      } else {
        setDeliveryDetails(activeOccurrencesDetails);
      }
      setPastOccurrenceDetail(pastOccurrences);
      setRowValidationError(false);
      setAmountValidate({ rowError: false, maxAmount: '' });
    }
  }, [isOpen, clearDeliveryDetails]);
  return (
    <Dialog
      className="dialog-box"
      open={isOpen}
      onClose={onCancel}
      fullWidth
      maxWidth="md">
      <DialogTitle className="dialog-title">
        <Box display="flex" justifyContent="flex-end">
          {(title || viewModeTitle) && (
            <Box flexGrow={1}>{viewMode ? viewModeTitle : title}</Box>
          )}
          <Box alignItems="right" justifyContent={'flex-end'}>
            <IconButton onClick={onCancel} m={0} p={0}>
              <CloseIcon sx={closeIconSx} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <div style={{ marginBottom: '20px', marginTop: '10px' }}>
          <Text
            fontWeight="600"
            color={isCreditValueExceed ? RED : GREEN}
            fontSize="0.9rem"
            lineHeight="140%">
            {creditSum} &nbsp; {DELIVERY_DETAILS.mtCo2} &nbsp; out of{' '}
            {creditsAvailable} &nbsp;
            {DELIVERY_DETAILS.mtCo2} &nbsp; {DELIVERY_DETAILS.message}
          </Text>
          {isCreditValueExceed && (
            <Text
              fontWeight="400"
              color="#DA291C"
              fontSize="0.9rem"
              lineHeight="140%">
              {`
              ${DELIVERY_DETAILS.warning} ${creditsAvailable}.
              `}
            </Text>
          )}
          {deliveryDetailsForSale && rowValidationError && (
            <Text
              fontWeight="400"
              color="#DA291C"
              fontSize="0.9rem"
              lineHeight="140%">
              {DELIVERY_DETAILS.sale_max_amount_error}
            </Text>
          )}
          {validationError && (
            <Text
              fontWeight="400"
              color="#DA291C"
              fontSize="0.9rem"
              lineHeight="140%">
              {DELIVERY_DETAILS.row_total_submit_error}
            </Text>
          )}
        </div>
        <Wrapper margin="0rem" border="2px solid #D3D3D3">
          <Text
            fontWeight="400"
            color="#333333"
            fontSize="0.95rem"
            lineHeight="1rem">
            {DELIVERY_DETAILS.occurs_on}
          </Text>
          <Text
            fontWeight="400"
            color="#333333"
            fontSize="0.95rem"
            lineHeight="1rem"
            paddingLeft="5.8rem">
            {DELIVERY_DETAILS.amount}{' '}
            <Text
              fontWeight="400"
              color="#63666A"
              fontSize="0.95rem"
              lineHeight="1rem">
              &nbsp;
              {DELIVERY_DETAILS.mtCo2}
            </Text>
          </Text>
          <Text
            fontWeight="400"
            color="#333333"
            fontSize="0.95rem"
            lineHeight="2rem"
            paddingLeft="3.2rem">
            {DELIVERY_DETAILS.delivery_status}
          </Text>
        </Wrapper>
        <Wrapper maxHeight="15rem" flexDirection="column" data-testid="row">
          {deliveryDetails.map((item, index) => {
            const indexVal = index;
            return (
              <OccurrenceDetails
                key={item.id}
                viewMode={viewMode}
                isRadioButton={hasRadioButton}
                isEditable={isEditable}
                isDisabled={checkTernaryCondition(
                  deliveryDetailsForSale,
                  setRowDisabled(editMode, indexVal, deliveryDetails, item),
                  item.isApiPopulated,
                )}
                date={date}
                setDate={setDate}
                mtAmount={mtAmount}
                minDeliveryDate={minDeliveryDate}
                projectObj={projectObj}
                vintages={vintages}
                combinedDetails={[...deliveryDetails, ...pastOccurrenceDetail]}
                setRowValidationError={setRowValidationError}
                setMtAmount={setMtAmount}
                amountValidate={amountvalidate}
                setAmountValidate={setAmountValidate}
                radioButtonValue={radioButtonValue}
                setRadioButtonValue={setRadioButtonValue}
                setDeliveryDetails={setDeliveryDetails}
                deliveryDetails={deliveryDetails}
                pastDeliveryDetails={pastOccurrenceDetail}
                index={index}
                id={item.id}
                item={item}
                isPastOccurrence={false}
                className={'rowChild'}
                disableRemove={isLinkedToSale && item.isApiPopulated}
              />
            );
          })}
        </Wrapper>
        {!viewMode && (
          <Wrapper
            margin="0.5rem 0rem 1rem"
            disabled={
              deliveryDetails.filter((detail) => !detail.rowValid).length > 0 &&
              deliveryDetails.length > 0 &&
              deliveryDetailsForSale
            }
            onClick={() => {
              handleClickAddOccurrence();
              markFormDirty();
            }}>
            <AddIcon />
            <AddOccurrenceLabel>
              {DELIVERY_DETAILS.add_occurrence_label}
            </AddOccurrenceLabel>
          </Wrapper>
        )}

        {hasPastOccurrences && (
          <div>
            <Text
              fontWeight="400"
              color="#007CB0"
              fontSize="0.9rem"
              lineHeight="2rem"
              cursor="pointer"
              onClick={() => setShowPastOccurrence(!showPastOccurrence)}>
              {showPastOccurrence
                ? DELIVERY_DETAILS.hide_past_occurrences
                : DELIVERY_DETAILS.show_past_occurrences}
            </Text>
            {showPastOccurrence && (
              <>
                <Wrapper margin="0rem" border="2px solid #D3D3D3">
                  <Text
                    fontWeight="400"
                    color="#333333"
                    fontSize="0.95rem"
                    lineHeight="1rem">
                    {DELIVERY_DETAILS.occurs_on}
                  </Text>
                  <Text
                    fontWeight="400"
                    color="#333333"
                    fontSize="0.95rem"
                    lineHeight="1rem"
                    paddingLeft="5.8rem">
                    {DELIVERY_DETAILS.amount}{' '}
                    <Text
                      fontWeight="400"
                      color="#63666A"
                      fontSize="0.95rem"
                      lineHeight="1rem">
                      &nbsp;
                      {DELIVERY_DETAILS.mtCo2}
                    </Text>
                  </Text>
                  <Text
                    fontWeight="400"
                    color="#333333"
                    fontSize="0.95rem"
                    lineHeight="2rem"
                    paddingLeft="3.2rem">
                    {DELIVERY_DETAILS.delivery_status}
                  </Text>
                </Wrapper>
                <Wrapper
                  maxHeight="15rem"
                  flexDirection="column"
                  data-testid="pastRow">
                  {pastOccurrenceDetail.map((item, index) => {
                    const indexVal = index;
                    return (
                      <OccurrenceDetails
                        key={item.id}
                        viewMode={viewMode}
                        isRadioButton={hasRadioButton}
                        isEditable={isEditable}
                        date={item.occursOn}
                        minDeliveryDate={minDeliveryDate}
                        projectObj={projectObj}
                        vintages={vintages}
                        combinedDetails={[
                          ...deliveryDetails,
                          ...pastOccurrenceDetail,
                        ]}
                        setRowValidationError={setRowValidationError}
                        setDate={setDate}
                        mtAmount={item.mtAmount}
                        setMtAmount={setMtAmount}
                        amountValidate={{ rowError: false, maxAmount: '' }}
                        setAmountValidate={() => {}}
                        radioButtonValue={radioButtonValue}
                        setRadioButtonValue={setRadioButtonValue}
                        setDeliveryDetails={setPastOccurrenceDetail}
                        deliveryDetails={pastOccurrenceDetail}
                        activeDeliveryDetails={deliveryDetails}
                        index={index}
                        id={item.id}
                        item={item}
                        isPastOccurrence={true}
                        className={'rowChild'}
                        disableRemove={isLinkedToSale && item.isApiPopulated}
                        isDisabled={checkTernaryCondition(
                          deliveryDetailsForSale,
                          setRowDisabled(
                            editMode,
                            indexVal,
                            deliveryDetails,
                            item,
                          ),
                          item.isApiPopulated,
                        )}
                      />
                    );
                  })}
                </Wrapper>
              </>
            )}
          </div>
        )}
        {formError && (
          <Info>
            <InfoOutlinedIcon sx={{ fontSize: '1.2rem' }} color="error" />
            <Text fontWeight="400" color="#d32f2f" fontSize="0.9rem">
              {DELIVERY_DETAILS.info}
            </Text>
          </Info>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        {!viewMode && (
          <Button
            onClick={() => validateForm()}
            variant={DELIVERY_DETAILS.button_variant}
            color="success"
            disabled={isConfirmDisabled}>
            {acceptCtnLabel}
          </Button>
        )}
        {!viewMode && (
          <Button
            onClick={onCancel}
            color={DELIVERY_DETAILS.primary_color}
            sx={{ textTransform: 'none' }}>
            {declineCtnLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DeliveryDetailsModal.propTypes = {
  viewMode: PropTypes.bool,
  viewModeTitle: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  acceptCtnLabel: PropTypes.string,
  declineCtnLabel: PropTypes.string,
  creditsAvailable: PropTypes.number,
  setProjectDeliveryDetails: PropTypes.func,
  setProjectPastOccurrenceDetails: PropTypes.func,
  hasRadioButton: PropTypes.bool,
  setIsDeliveryDetailsOpen: PropTypes.func,
  isEditable: PropTypes.bool,
  clearDeliveryDetails: PropTypes.func,
  pastOccurrences: PropTypes.array,
  activeOccurrencesDetails: PropTypes.array,
  deliveryDetailsForSale: PropTypes.array,
  hasPastOccurrences: PropTypes.bool,
  deliveryDetailList: PropTypes.array,
  minDeliveryDate: PropTypes.instanceOf(Date),
  projectObj: PropTypes.object,
  vintages: PropTypes.array,
  editMode: PropTypes.bool,
  isModalWarningDialogOpen: PropTypes.bool,
  isLinkedToSale: PropTypes.bool,
};

export default DeliveryDetailsModal;
