import { pathName } from 'Routes/routes.const';
import BackButton from 'components/Backbutton/BackButton.react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  HeaderContainer,
  HeaderTitle,
} from '../ListViewHeader/ListViewHeader.style';
import { TitleWithLabels, Label } from './SummaryViewHeader.style';
import CustomOptionsButton from 'components/SimpleOptionsButton/CustomOptionsButton.react';
import { QUILL_GRAY } from 'theme/GlobalColors';

const SummaryViewHeader = ({
  previouspath = pathName.originationProject.view,
  previousstate,
  title = '',
  actions = [],
  status = '',
  isEdited = false,
  noBackButton = false,
  padding,
  type,
  gap,
  marginRight,
}) => {
  return (
    <div>
      {!noBackButton && (
        <BackButton
          previouspath={previouspath}
          previousstate={previousstate}
          marginBottom={'0.5rem'}
          isEdited={isEdited}
        />
      )}
      <HeaderContainer padding={padding}>
        <TitleWithLabels gap={gap}>
          <HeaderTitle
            marginRight={marginRight}
            data-testid="summary-view-header-title">
            {title}
          </HeaderTitle>

          {status.length > 0 && (
            <Label data-testid="project-status">{status}</Label>
          )}
          {type && (
            <Label data-testid="type" backgroundColor={QUILL_GRAY}>
              {type}
            </Label>
          )}
        </TitleWithLabels>

        {actions.length > 0 && (
          <CustomOptionsButton data-testid="meatball-menu" options={actions} />
        )}
      </HeaderContainer>
    </div>
  );
};

export default SummaryViewHeader;

SummaryViewHeader.propTypes = {
  marginRight: PropTypes.string,
  gap: PropTypes.string,
  type: PropTypes.string,
  padding: PropTypes.string,
  previouspath: PropTypes.string,
  previousstate: PropTypes.object,
  title: PropTypes.string,
  actions: PropTypes.array,
  status: PropTypes.string,
  isEdited: PropTypes.bool,
  noBackButton: PropTypes.bool,
};
