import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  checkTernaryCondition,
  convertTimeStampFormat,
  verifyDateFormat,
} from 'utils/helper';
import {
  FormFields,
  FormLabels,
  ErroWrapper,
  ManualErrorWrapper,
} from '../FormStyles';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  BLACK,
  WHITE,
  GRAYISH_BLUE,
} from 'theme/GlobalColors';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FORM_CONTENT } from '../FormComponents.content';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';

const CustomDatePicker = ({
  label,
  value,
  inputFormat,
  onUpdate,
  icon,
  open,
  onOpen,
  onClose,
  placeholder,
  width,
  onClickHandler,
  error,
  errorMessage,
  isNotOutlined,
  height,
  minWidth,
  fontSize,
  hasNoLabel,
  labelColor,
  isDisabled = false,
  setDisabledYears,
  startYear,
  endYear,
  hasNoBottomMargin,
  customSelectedDateColor,
  showOnlyYears = false,
  hasSideLabels,
  display,
  gap,
  alignItems,
  labelMarginTop,
  labelMarginBottom,
  calendarOpenDate,
  dateList,
  minDate,
  maxDate,
  dateTextColor,
  labelWidth,
  labelHeight,
  marginBottom,
  notShowValue,
  defaultBorderColor,
  alertIconStyling,
  validationError,
  isEditable = true,
  isDropdownDateComponent = false,
}) => {
  const [formatError, setFormatError] = useState(false);
  const disabledDaysFromList = (date) => {
    let count = 0;
    const filterDatesArray = dateList.filter(
      (item) => item !== undefined && item !== null,
    );
    if (filterDatesArray?.length > 0 && filterDatesArray[0] !== undefined) {
      filterDatesArray?.forEach((item) => {
        if (
          date.month() === item?.getMonth() &&
          date?.year() === item?.getFullYear() &&
          date?.date() === item?.getDate()
        ) {
          count = count + 1;
        }
      });
    }
    let countResult = false;
    if (count > 0) {
      countResult = true;
    }
    return countResult;
  };
  const shouldDisableYear = (date) => {
    if (date.get('year') < startYear || date.get('year') > endYear) return true;
  };
  useEffect(() => {
    if (value !== '' && value !== placeholder) {
      setFormatError(!verifyDateFormat(convertTimeStampFormat(value)));
    } else if (value === placeholder) {
      setFormatError(false);
    }
  }, [value]);
  return (
    <FormFields
      minWidth={minWidth}
      hasNoBottomMargin={hasNoBottomMargin}
      marginBottom={marginBottom}
      display={display}
      gap={gap}
      alignItems={checkTernaryCondition(
        isDropdownDateComponent,
        '',
        alignItems,
      )}
      direction={checkTernaryCondition(hasSideLabels, 'row', '')}>
      {!hasNoLabel && (
        <FormLabels
          style={{ width: labelWidth, height: labelHeight }}
          lineHeight={checkTernaryCondition(
            isDropdownDateComponent,
            '2.188rem',
            '',
          )}
          color={checkTernaryCondition(labelColor, labelColor, DARK_CHARCOAL)}
          flexDirection={(error || formatError || validationError) && 'row'}
          marginTop={labelMarginTop}
          gap={(error || formatError || validationError) && '0.25rem'}
          marginBottom={labelMarginBottom}
          iserror={error || formatError || validationError}>
          {label}
          {(error || formatError || validationError) && (
            <AlertIcon
              data-testid="custom-date-error"
              style={alertIconStyling}
            />
          )}
        </FormLabels>
      )}
      <ManualErrorWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...checkTernaryCondition(maxDate, { maxDate: dayjs(maxDate) }, {})}
            {...checkTernaryCondition(minDate, { minDate: dayjs(minDate) }, {})}
            shouldDisableYear={checkTernaryCondition(
              setDisabledYears,
              shouldDisableYear,
              null,
            )}
            disabled={isDisabled}
            shouldDisableDate={checkTernaryCondition(
              dateList?.length > 0,
              disabledDaysFromList,
              null,
            )}
            defaultCalendarMonth={checkTernaryCondition(
              calendarOpenDate,
              dayjs(calendarOpenDate),
              null,
            )}
            PaperProps={{
              sx: {
                '& .MuiYearPicker-root .Mui-selected': {
                  backgroundColor: customSelectedDateColor,
                  '&:hover, &:focus': {
                    '&.Mui-selected': {
                      backgroundColor: customSelectedDateColor,
                      color: WHITE,
                    },
                  },
                },
                '& .Mui-disabled': {
                  color: BLACK,
                  backgroundColor: 'inherit',
                  '&.Mui-selected': {
                    backgroundColor: customSelectedDateColor,
                    color: WHITE,
                  },
                },
                '& .MuiPickersDay-root:focus': {
                  backgroundColor: 'white',
                },
                '& .MuiPickersDay-root': {
                  '&.Mui-selected': {
                    backgroundColor: customSelectedDateColor,
                  },
                  '&:hover, &:focus': {
                    '&.Mui-selected': {
                      backgroundColor: customSelectedDateColor,
                    },
                  },
                },
              },
            }}
            value={notShowValue ? null : value}
            views={checkTernaryCondition(showOnlyYears, ['year'], null)}
            inputFormat={inputFormat}
            onChange={onUpdate}
            components={{
              OpenPickerIcon: icon,
            }}
            open={open}
            InputAdornmentProps={{
              sx: {
                '& .MuiIconButton-root': {
                  paddingRight: checkTernaryCondition(
                    isNotOutlined,
                    '1rem',
                    '0.5rem',
                  ),
                },
              },
            }}
            onOpen={onOpen}
            onClose={onClose}
            renderInput={(params) => (
              <TextField
                data-testid="date-picker"
                focused={open}
                variant={checkTernaryCondition(
                  isNotOutlined,
                  'standard',
                  'outlined',
                )}
                {...params}
                error={error}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder,
                  readOnly: !isEditable,
                  sx: {
                    color: dateTextColor,
                    fontSize: '0.875rem',
                    '&::placeholder': {
                      color: COOL_GRAY,
                      opacity: 1,
                      fontSize: '0.875rem',
                      visibility: 'hidden',
                    },
                  },
                }}
                sx={{
                  width: width,
                  '& .MuiInputBase-root': {
                    fontSize: fontSize,
                    height: height,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: !error && DARK_GREEN,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: defaultBorderColor,
                    borderRadius: '2px',
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: !error && DARK_GREEN,
                    },
                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: error && GRAYISH_BLUE,
                    },
                  '& .MuiOutlinedInput-input': {
                    paddingLeft: '0.5rem',
                  },
                  '&& .Mui-disabled': {
                    '-webkit-text-fill-color': BLACK,
                  },
                }}
                onClick={checkTernaryCondition(
                  isDisabled,
                  (e) => e.preventDefault(),
                  onClickHandler,
                )}
              />
            )}
          />
        </LocalizationProvider>
        {error && errorMessage !== '' && (
          <ErroWrapper>{errorMessage}</ErroWrapper>
        )}
        {formatError && (
          <ErroWrapper>{FORM_CONTENT.manualErrorString}</ErroWrapper>
        )}
      </ManualErrorWrapper>
    </FormFields>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  inputFormat: PropTypes.string,
  onUpdate: PropTypes.func,
  icon: PropTypes.node,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClickHandler: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  isNotOutlined: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNoLabel: PropTypes.bool,
  labelColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  setDisabledYears: PropTypes.bool,
  startYear: PropTypes.number,
  endYear: PropTypes.number,
  hasNoBottomMargin: PropTypes.bool,
  customSelectedDateColor: PropTypes.string,
  showOnlyYears: PropTypes.bool,
  hasSideLabels: PropTypes.bool,
  display: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignItems: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  calendarOpenDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  labelWidth: PropTypes.string,
  labelHeight: PropTypes.string,
  validationError: PropTypes.bool,
  isEditable: PropTypes.bool,
  notShowValue: PropTypes.string,
  defaultBorderColor: PropTypes.string,
  isDropdownDateComponent: PropTypes.bool,
  alertIconStyling: PropTypes.shape({}),
  dateList: PropTypes.array,
  dateTextColor: PropTypes.string,
};

export default CustomDatePicker;
