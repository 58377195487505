import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import {
  annualYieldDropdown,
  MODAL_CONTENT,
  cropList,
  errorSetter,
  findDefaultDates,
  findDateExceededError,
  getRange,
  managementTypeDropdownValues,
  productionPuposeConstants,
  plantingDensityConstants,
  cropDensityAndDepthDropdownList,
  formatErrorMessage,
} from './CropType.content';
import {
  FieldSectionWrapper,
  InputSx,
  outlinedDropdownInputSx,
} from '../Modals.style';
import { addBtnSx } from 'pages/ParticipantDataCollection/ReportingComponents/ModalLessAccordionDisplayBox/ModalLessAccordionDisplayBox.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  addZeroBeforeDot,
  checkTernaryCondition,
  findObjectWithKey,
  isEmpty,
  isNumberOrStringOfTheNumber,
  returnTrueWhenValueIsNullOrEmpty,
} from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { ReactComponent as Calendericon } from '../../../../../../assets/icons/calenderIcon.svg';
import { useEffect, useState, useContext } from 'react';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  CropValueInput,
  CropValueDollar,
  CropTypeModalToolTipStyle,
} from './CropTypeModal.style';
import {
  TextWrapper,
  deleteIconSx,
  MultiFieldWrapper,
} from 'components/FormComponents/FormStyles';
import {
  COOL_GRAY,
  DARK_GREEN,
  DISABLED_TEXT_COLOR,
  RED,
  BLACK,
  FARM_FIELD_LABEL_COLOR,
  FORM_BG_BORDER,
} from 'theme/GlobalColors';
import { DATE_FORMAT, INPUT_CATEGORY } from 'utils/config';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import {
  cropSeasons,
  productionPurpose,
} from '../AddCropModal/AddCropModal.content';
import {
  CropMainWrapper,
  SectionWrapper,
} from '../AddCropModal/AddCropModal.style';
import IrrigationModal from '../IrrigationModal/IrrigationModal.react';
import SoilAmendmentsModal from '../SoilAmendmentsModal/SoilAmendmentsModal.react';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import moment from 'moment';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import {
  CROP_AREA,
  PLANTING_DENSITY,
  SEEDING_DEPTH,
  ANNUAL_YIELD,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const CropTypeModal = ({
  setYearWiseCashCropList,
  yearWiseCashCropList,
  cropYear,
  id,
  data: formValue,
  setIsDeleteModalOpen,
  isSubmitClicked,
  setIsSubmitClicked,
  disableAllFieldActions = false,
  makeFormDirty = () => null,
}) => {
  const {
    unitOfMeasureList: { cropAndLandUse },
  } = useContext(uomContext);
  const {
    saveCropListData,
    submitData,
    handlePracticeTypeDelete,
    isFieldDisabled,
    totalFieldArea,
  } = useContext(implementedActivitiesWithOpsDataContext);
  const {
    allCropTypeDropDown,
    cropTypeDropDown,
    residueTypeDropdown,
    managementTypeDropDown,
    irrigationNoneId,
    fallowDropDown,
    cashCropDropDown,
    cropCoverDropDown,
    soilAmendmentsDropdown,
    irrigationMethodDropDown,
  } = useContext(baselineDropdownContext);
  const [datePickerPlantedOpen, setDatePickerPlantedOpen] = useState(false);
  const [datePickerHarvestedOpen, setDatePickerHarvestedOpen] = useState(false);
  const [datePlantedSeasonError, setDatePlantedSeasonError] = useState({
    winter: false,
    summer: false,
  });
  const [dateHarvestedSeasonError, setDateHarvestedSeasonError] = useState({
    winter: false,
    summer: false,
  });
  const [shouldSubmitWork, setShouldSubmitWork] = useState(false);
  const [dateExceedError, setDateExceedError] = useState({
    planted: false,
    harvested: false,
  });
  const [errorState, setErrorState] = useState(errorSetter(formValue));
  const [disableAnnualField, setDisableAnnualField] = useState(false);
  const [disableAnnualFieldUom, setDisableAnnualFieldUom] = useState(false);

  const customFieldChangeHandler = (key, value) => {
    saveCropListData({ ...formValue, [key]: value }, id, cropYear);
  };

  const setFormValue = (value) => {
    saveCropListData(value, id, cropYear);
  };

  const handleTextFieldValueChange = (event, eventValue, key, isNumber) => {
    const finalValue = event?.target.value.trim() ? event?.target.value : '';
    if (isNumber) {
      event?.target.value.match(/^\d*(\.\d{0,2})?$/) &&
        customFieldChangeHandler(key, addZeroBeforeDot(finalValue));
    } else {
      customFieldChangeHandler(key, finalValue);
    }
  };

  const isCoverCropSelected = () => {
    return (
      formValue?.productionPurpose?.toLowerCase() ===
      productionPuposeConstants.COVER_CROP?.toLowerCase()
    );
  };
  const isFallowSelected = () => {
    return (
      formValue?.productionPurpose?.toLowerCase() ===
      MODAL_CONTENT.fallow_crop_value.toLowerCase()
    );
  };

  const isCashCropSelected = () => {
    const cropTypeValue = allCropTypeDropDown.find(
      (item) => item.value === formValue.cropId,
    )?.label;
    return cropList.includes(cropTypeValue);
  };
  const setModalErrorState = (value, fieldName) => {
    /* This comment is for future use */
    let newErrorState = { ...errorState };
    newErrorState[fieldName] = value === '';
    setErrorState(newErrorState);
  };
  const datePlantedProps = {
    labelColor: FARM_FIELD_LABEL_COLOR,
    defaultBorderColor: FORM_BG_BORDER,
    minDate: checkTernaryCondition(
      formValue?.cropSeason?.toLowerCase() === 'summer',
      `${+cropYear}-01-01`,
      `${+cropYear - 1}-08-01`,
    ),
    maxDate: checkTernaryCondition(
      formValue?.cropSeason?.toLowerCase() === 'summer',
      `${+cropYear}-08-31`,
      `${+cropYear}-02-${checkTernaryCondition(
        moment(+cropYear).isLeapYear(),
        29,
        28,
      )}`,
    ),
    notShowValue: isFallowSelected(),
    calendarOpenDate: findDefaultDates(
      formValue.cropSeason,
      MODAL_CONTENT.date_planted,
      +cropYear,
    ),
    label: MODAL_CONTENT.date_planted,
    value: checkTernaryCondition(
      formValue?.cropPlantDate === '',
      MODAL_CONTENT.selected_date,
      formValue?.cropPlantDate,
    ),
    inputFormat: DATE_FORMAT,
    onUpdate: (newValue) => {
      setErrorState({
        ...errorState,
        cropPlantDate: newValue?.toDate() === '',
      });
      if (newValue?.toDate() === null || newValue?.toDate() === undefined) {
        customFieldChangeHandler('cropPlantDate', '');
      } else {
        customFieldChangeHandler('cropPlantDate', newValue?.toDate());
      }
      setShouldSubmitWork(true);
      makeFormDirty();
    },
    icon: Calendericon,
    open: datePickerPlantedOpen,
    onOpen: () =>
      isFallowSelected() ? () => {} : setDatePickerPlantedOpen(true),
    onClose: () => setDatePickerPlantedOpen(false),
    placeholder: MODAL_CONTENT.selected_date,
    width: '8rem',
    height: '2rem',
    onClickHandler: isFallowSelected()
      ? () => {}
      : () => setDatePickerPlantedOpen(true),
    minWidth: '7.5rem',
    fontSize: '14px',
    error:
      (errorState.cropPlantDate ||
        dateExceedError.planted ||
        Object.values(datePlantedSeasonError).includes(true)) &&
      !isFallowSelected() &&
      !isFieldDisabled,
    customSelectedDateColor: DARK_GREEN,
    isDisabled: isFallowSelected() || isFieldDisabled || disableAllFieldActions,
  };
  const dateHarvestedProps = {
    labelColor: FARM_FIELD_LABEL_COLOR,
    defaultBorderColor: FORM_BG_BORDER,
    minDate: checkTernaryCondition(
      formValue?.cropSeason?.toLowerCase() === 'summer',
      `${+cropYear}-06-01`,
      `${+cropYear - 1}-10-01`,
    ),
    maxDate: checkTernaryCondition(
      formValue?.cropSeason?.toLowerCase() === 'summer',
      `${+cropYear}-12-31`,
      `${+cropYear}-08-31`,
    ),
    notShowValue: isFallowSelected(),
    calendarOpenDate: findDefaultDates(
      formValue.cropSeason,
      MODAL_CONTENT.date_harvested,
      +cropYear,
    ),
    label: MODAL_CONTENT.date_harvested,
    minWidth: '7.5rem',
    value: checkTernaryCondition(
      formValue?.cropTerminationDate === '',
      MODAL_CONTENT.selected_date,
      formValue?.cropTerminationDate,
    ),
    inputFormat: DATE_FORMAT,
    onUpdate: (newValue) => {
      if (newValue?.toDate() === null || newValue?.toDate() === undefined) {
        customFieldChangeHandler('cropTerminationDate', '');
      } else {
        customFieldChangeHandler('cropTerminationDate', newValue?.toDate());
      }
      setErrorState({
        ...errorState,
        cropHarvestDate: newValue?.toDate() === '',
      });
      setShouldSubmitWork(true);
      makeFormDirty();
    },
    icon: Calendericon,
    open: datePickerHarvestedOpen,
    onOpen: () =>
      isFallowSelected() ? () => {} : setDatePickerHarvestedOpen(true),
    onClose: () => setDatePickerHarvestedOpen(false),
    placeholder: MODAL_CONTENT.selected_date,
    width: '8rem',
    height: '2rem',
    fontSize: '14px',
    onClickHandler: () =>
      isFallowSelected() ? () => {} : setDatePickerHarvestedOpen(true),
    error:
      !isFallowSelected() &&
      !isFieldDisabled &&
      (errorState.cropHarvestDate ||
        Object.values(dateHarvestedSeasonError).includes(true) ||
        dateExceedError.harvested),
    customSelectedDateColor: DARK_GREEN,
    isDisabled: isFallowSelected() || isFieldDisabled || disableAllFieldActions,
  };
  const agricultureResiduesDropdownProps = {
    defaultDropdownColor: FORM_BG_BORDER,
    label: MODAL_CONTENT.agriculture_residues_managed,
    value: checkTernaryCondition(
      formValue?.agricultureResiduesManaged === '',
      MODAL_CONTENT.agriculture_residues_managed_placeholder,
      formValue?.agricultureResiduesManaged,
    ),
    headerColor: FARM_FIELD_LABEL_COLOR,
    width: '14rem',
    minWidth: '14rem',
    height: '2rem',
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
    hasNoBottomMargin: true,
    customSx: outlinedDropdownInputSx,
    name: MODAL_CONTENT.agriculture_residues_managed_name,
    ariaLabel: MODAL_CONTENT.agriculture_residues_managed_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.agriculture_residues_managed_placeholder,
    customPlaceholderColor: COOL_GRAY,
    error: errorState.agricultureResiduesManaged,
    isNotOutlined: false,
    dropdownlist: residueTypeDropdown,
    setFormFieldValue: customFieldChangeHandler,
    field_key: MODAL_CONTENT.agriculture_residues_managed_name,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: MODAL_CONTENT.agriculture_residues_managed_name,
    onUpdate: () => {
      setShouldSubmitWork(true);
      makeFormDirty();
    },
  };
  const setCropNameInList = (event) => {
    const cropName = event.target.value;
    const oldName = cropTypeDropDown.filter(
      (item) => item.value === formValue?.cropId,
    )[0]?.label;
    const name = cropTypeDropDown.filter((item) => item.value === cropName)[0]
      ?.label;
    let copyFirstYearData = yearWiseCashCropList?.firstYearCrops;
    let copySecondYearData = yearWiseCashCropList?.secondYearCrops;
    let copyThirdYearData = yearWiseCashCropList?.thirdYearCrops;
    switch (+cropYear) {
      case 2020:
        if (copyFirstYearData?.includes(oldName)) {
          copyFirstYearData?.pop(oldName);
        }
        if (name !== undefined) {
          copyFirstYearData?.push(name);
        }
        setYearWiseCashCropList({
          ...yearWiseCashCropList,
          firstYearCrops: copyFirstYearData,
        });
        break;
      case 2021:
        if (copySecondYearData?.includes(oldName)) {
          copySecondYearData?.pop(oldName);
        }
        if (name !== undefined) {
          copySecondYearData?.push(name);
        }
        setYearWiseCashCropList({
          ...yearWiseCashCropList,
          secondYearCrops: copySecondYearData,
        });
        break;
      case 2022:
        if (copyThirdYearData?.includes(oldName)) {
          copyThirdYearData?.pop(oldName);
        }
        if (name !== undefined) {
          copyThirdYearData?.push(name);
        }
        setYearWiseCashCropList({
          ...yearWiseCashCropList,
          thirdYearCrops: copyThirdYearData,
        });
        break;
    }
  };
  const submitBtnHandler = () => {
    setErrorState(
      errorSetter(formValue, allCropTypeDropDown, managementTypeDropDown),
    );
  };
  const practiceSubmitHandler = (data, id, listType) => {
    const newList = formValue[listType].filter((item) => item.id === id);
    if (newList) {
      setFormValue({
        ...formValue,
        [listType]: formValue[listType].map((item) =>
          item.id === id ? data : item,
        ),
      });
    } else {
      setFormValue({
        ...formValue,
        [listType]: [...formValue[listType], data],
      });
    }
  };

  /**
   * @description Handles the validation of form values against predefined ranges for planting density and annual yield.
   *
   * @param {Object} formValue - The form values to be validated.
   * @returns {void}
   */
  function valueRangeHandler(formValue) {
    const {
      cropId,
      cropPlannedManagementTypeId,
      plantingDensity,
      annualYield,
      productionPurpose,
    } = formValue;

    // finding crop type value
    const cropTypeValue = allCropTypeDropDown.find(
      (item) => item.value === cropId,
    )?.label;

    // finding management type value
    const managementTypeValue = managementTypeDropDown.find(
      (item) => item.value === cropPlannedManagementTypeId,
    )?.label;
    const { densityRange, yieldRange } = getRange(
      cropTypeValue,
      productionPurpose,
      managementTypeValue,
    );
    setErrorState((prevErrorState) => ({
      ...prevErrorState,
      plantingDensity:
        !isEmpty(plantingDensity) &&
        (Number(plantingDensity) < densityRange[0] ||
          Number(plantingDensity) > densityRange[1]),
      plantingDensityErrorMessage: densityRange,
    }));

    if (
      !isEmpty(managementTypeValue) &&
      Object.values(managementTypeDropdownValues).includes(managementTypeValue)
    ) {
      setErrorState((prevErrorState) => ({
        ...prevErrorState,
        annualYieldRange:
          !isEmpty(annualYield) &&
          (Number(annualYield) < yieldRange[0] ||
            Number(annualYield) > yieldRange[1]),
        annualYieldErrorMessage: yieldRange,
      }));
    }
  }

  /**
   * handleDisableValidation
   *
   * This function handles the logic for disabling validation of plantingDensityUom based on the certain conditions
   *
   * @function
   * @param {void}
   * @returns {void}
   */

  const handleDisableValidation = () => {
    const list = cropDensityAndDepthDropdownList(
      findObjectWithKey(cropAndLandUse, PLANTING_DENSITY).PLANTING_DENSITY,
    );
    /* istanbul ignore else */
    if (formValue.productionPurpose === productionPuposeConstants.COVER_CROP) {
      formValue.plantingDensityUom = list.find(
        (item) => item.label === plantingDensityConstants.LBS_PER_ACRE,
      )?.value;
    } else if (
      formValue.productionPurpose === productionPuposeConstants.CASH_CROP
    ) {
      const cropTypeValue = allCropTypeDropDown.find(
        (item) => item.value === formValue.cropId,
      )?.label;

      /* istanbul ignore else */
      if (cropList.includes(cropTypeValue)) {
        formValue.plantingDensityUom = list.find(
          (item) => item.label === plantingDensityConstants.SEEDS_PER_ACRE,
        )?.value;
      }
    }
  };
  useEffect(() => {
    setDateExceedError({
      harvested: checkTernaryCondition(
        !shouldSubmitWork,
        new Date(formValue.cropTerminationDate) <=
          new Date(formValue?.cropPlantDate),
        false,
      ),
      planted: false,
    });
    // handle the range validation for plantingDensity and annualYield
    valueRangeHandler(formValue);
    //disable the plantingDensity uom based on certain conditions
    handleDisableValidation();
    if (isNaN(formValue.cropAreaUom)) {
      customFieldChangeHandler(
        MODAL_CONTENT.CROP_AREA_UOM,
        findObjectWithKey(cropAndLandUse, CROP_AREA).CROP_AREA[0]?.uomId,
      );
    }
  }, [formValue]);
  useEffect(() => {
    if (submitData.editMode === true && formValue.isFetchedFromAPI) {
      submitBtnHandler();
    }
  }, [submitData]);
  useEffect(() => {
    const uomList = annualYieldDropdown(
      findObjectWithKey(cropAndLandUse, ANNUAL_YIELD).ANNUAL_YIELD,
    );
    if (
      allCropTypeDropDown.find((item) => item.value === formValue.cropId)
        ?.label === MODAL_CONTENT.fallow ||
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.flag
    ) {
      setFormValue({ ...formValue, annualYield: 0, annualYieldUom: '' });
      setDisableAnnualField(true);
      setDisableAnnualFieldUom(true);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.silage
    ) {
      customFieldChangeHandler(
        MODAL_CONTENT.ANNUAL_YIELD_UOM,
        uomList?.find((item) => item.label === MODAL_CONTENT.TON_PER_ACRE)
          ?.value,
      );
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.grain &&
      cropList.includes(
        allCropTypeDropDown.find((item) => item.value === formValue.cropId)
          ?.label,
      )
    ) {
      customFieldChangeHandler(
        MODAL_CONTENT.ANNUAL_YIELD_UOM,
        uomList?.find((item) => item.label === MODAL_CONTENT.BUSHELS_PER_ACRE)
          ?.value,
      );
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.grain &&
      ![...cropList, MODAL_CONTENT.otherHarvested].includes(
        allCropTypeDropDown.find((item) => item.value === formValue.cropId)
          ?.label,
      )
    ) {
      customFieldChangeHandler(
        MODAL_CONTENT.ANNUAL_YIELD_UOM,
        uomList?.find((item) => item.label === MODAL_CONTENT.POUNDS_PER_ACRE)
          ?.value,
      );
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.grazing
    ) {
      customFieldChangeHandler(
        MODAL_CONTENT.ANNUAL_YIELD_UOM,
        uomList?.find(
          (item) => item.label === MODAL_CONTENT.PERCENT_HARVEST_EFFICIENCY,
        )?.value,
      );
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      allCropTypeDropDown.find((item) => item.value === formValue.cropId)
        ?.label === MODAL_CONTENT.otherHarvested
    ) {
      customFieldChangeHandler(MODAL_CONTENT.ANNUAL_YIELD_UOM, '');
      setDisableAnnualFieldUom(false);
      setDisableAnnualField(false);
    } else {
      setDisableAnnualField(false);
      setDisableAnnualFieldUom(false);
      setFormValue({ ...formValue, annualYield: '', annualYieldUom: '' });
    }
  }, [
    formValue.cropPlannedManagementTypeId,
    formValue.cropId,
    formValue.annualYield,
  ]);

  useEffect(() => {
    if (formValue?.cropSeason?.toLowerCase() === 'winter') {
      if (
        new Date(formValue.cropPlantDate) <
          new Date(`${+cropYear - 1}-07-31`) ||
        new Date(formValue.cropPlantDate) >
          new Date(
            `${+cropYear}-02-${checkTernaryCondition(
              moment(+cropYear).isLeapYear(),
              29,
              28,
            )}`,
          )
      ) {
        setDatePlantedSeasonError({ winter: true, summer: false });
      } else {
        setDatePlantedSeasonError({ winter: false, summer: false });
      }
    } else if (
      new Date(formValue.cropPlantDate) < new Date(`${+cropYear - 1}-12-31`) ||
      new Date(formValue.cropPlantDate) > new Date(`${+cropYear}-08-31`)
    ) {
      setDatePlantedSeasonError({ winter: false, summer: true });
    } else {
      setDatePlantedSeasonError({ winter: false, summer: false });
    }
    findDateExceededError(
      checkTernaryCondition(
        shouldSubmitWork,
        new Date(formValue.cropPlantDate) >=
          new Date(formValue?.cropTerminationDate),
        false,
      ),
      false,
      datePlantedSeasonError,
      setDateExceedError,
    );
  }, [formValue.cropPlantDate]);
  useEffect(() => {
    if (formValue?.cropSeason?.toLowerCase() === 'winter') {
      if (
        new Date(formValue.cropTerminationDate) <
          new Date(`${+cropYear - 1}-09-30`) ||
        new Date(formValue.cropTerminationDate) > new Date(`${+cropYear}-08-31`)
      ) {
        setDateHarvestedSeasonError({ winter: true, summer: false });
      } else {
        setDateHarvestedSeasonError({ winter: false, summer: false });
      }
    } else if (
      new Date(formValue.cropTerminationDate) <
        new Date(`${+cropYear}-05-31`) ||
      new Date(formValue.cropTerminationDate) > new Date(`${+cropYear}-12-31`)
    ) {
      setDateHarvestedSeasonError({ winter: false, summer: true });
    } else {
      setDateHarvestedSeasonError({ winter: false, summer: false });
    }
    findDateExceededError(
      false,
      checkTernaryCondition(
        shouldSubmitWork,
        new Date(formValue.cropPlantDate) >=
          new Date(formValue?.cropTerminationDate),
        false,
      ),
      dateHarvestedSeasonError,
      setDateExceedError,
    );
  }, [formValue.cropTerminationDate]);
  useEffect(() => {
    if (formValue.agricultureResiduesManaged === 1) {
      setFormValue({ ...formValue, cropResidueRemoved: '' });
    }
  }, [formValue.agricultureResiduesManaged]);

  return (
    <CropMainWrapper>
      <MultiFieldWrapper direction="row" gap="0.5rem">
        <RadioButtonsGroup
          hasTooltipOnLabel={isFieldDisabled || !disableAllFieldActions}
          formLabelTooltipCustomSx={CropTypeModalToolTipStyle}
          isDisabled
          tooltipContent={MODAL_CONTENT.crop_type_help_text}
          headerColor={FARM_FIELD_LABEL_COLOR}
          marginRight={'2rem'}
          marginTop={'0.1rem'}
          list={productionPurpose}
          marginBottom={'0rem'}
          label={MODAL_CONTENT.production_purpose}
          direction="row"
          selectedValue={formValue?.productionPurpose}
          checkedIsDarkGreen
          primaryError={errorState.productionPurpose}
          handleChange={(e) => {
            customFieldChangeHandler('productionPurpose', e.target.value);
            setErrorState({ ...errorState, productionPurpose: false });
            setShouldSubmitWork(true);
          }}
        />
        {!isFieldDisabled && !disableAllFieldActions && (
          <DeleteIconWithHoverEffect
            data-testid={`delete-icon-crop`}
            sx={{ ...deleteIconSx, marginBottom: '0.6rem' }}
            onClick={() => {
              const currDropDownList = checkTernaryCondition(
                formValue.productionPurpose.toLowerCase() ===
                  'Cash crop'.toLowerCase(),
                cashCropDropDown,
                checkTernaryCondition(
                  formValue.productionPurpose.toLowerCase() ===
                    'Cover crop'.toLowerCase(),
                  cropCoverDropDown,
                  fallowDropDown,
                ),
              );
              setIsDeleteModalOpen({
                flag: true,
                id: formValue?.id,
                type: checkTernaryCondition(
                  formValue?.productionPurpose === MODAL_CONTENT.fallow,
                  MODAL_CONTENT.fallow,
                  currDropDownList.filter(
                    (item) => item.value === formValue?.cropId,
                  )[0]?.label || '',
                ),
              });
            }}
          />
        )}
      </MultiFieldWrapper>
      <SectionWrapper alignment="baseline">
        <DropDown
          headerColor={FARM_FIELD_LABEL_COLOR}
          isDisabled
          minWidth="13rem"
          onUpdate={(e) => {
            setShouldSubmitWork(true);
            setCropNameInList(e);
            makeFormDirty();
          }}
          defaultDropdownColor={FORM_BG_BORDER}
          labelMarginTop={checkTernaryCondition(
            errorState.productionPurpose,
            '0.5rem',
            '0rem',
          )}
          customPlaceholderColor={COOL_GRAY}
          hasNoBottomMargin={true}
          label={MODAL_CONTENT.crop_type}
          value={checkTernaryCondition(
            formValue?.cropId === '',
            MODAL_CONTENT.select_crop_type,
            formValue?.cropId,
          )}
          stateValue={formValue?.cropId}
          setFormFieldValue={customFieldChangeHandler}
          setStateValue={() => {}}
          field_key="cropId"
          name={MODAL_CONTENT.crop_type}
          dropdownlist={allCropTypeDropDown}
          dropDownPlaceholder={MODAL_CONTENT.select_crop_type}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          error={errorState.cropId && !isFallowSelected() && !isFieldDisabled}
          setModalErrorState={setModalErrorState}
          fieldName="cropId"
          showLabelAsValue
        />
        <RadioButtonsGroup
          isDisabled
          headerColor={FARM_FIELD_LABEL_COLOR}
          customSx={{
            maxWidth: '12.9rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          marginRight={'2rem'}
          marginTop={'0.1rem'}
          list={cropSeasons}
          marginBottom={'0rem'}
          label={MODAL_CONTENT.crop_season}
          direction="row"
          selectedValue={formValue?.cropSeason}
          checkedIsDarkGreen
          primaryError={errorState.cropSeason}
          primaryErrorMessage={'is required'}
          handleChange={(e) => {
            customFieldChangeHandler('cropSeason', e.target.value);
            setErrorState({ ...errorState, cropSeason: false });
            setShouldSubmitWork(true);
            makeFormDirty();
          }}
        />
        <FieldSectionWrapper columnGap="1rem">
          <InputField
            isDisabled={isFieldDisabled || disableAllFieldActions}
            headerColor={FARM_FIELD_LABEL_COLOR}
            label={MODAL_CONTENT.crop_area}
            value={formValue?.cropArea}
            placeholder={MODAL_CONTENT.type_area}
            primaryError={
              errorState.cropArea || +formValue.cropArea > +totalFieldArea
            }
            primaryErrorMessage={checkTernaryCondition(
              isNumberOrStringOfTheNumber(formValue.cropArea, 0),
              MODAL_CONTENT.crop_area_greater_than_zero_validation,
              +formValue.cropArea > +totalFieldArea
                ? `${MODAL_CONTENT.total_field_area_error}- ${totalFieldArea} acres.`
                : '',
            )}
            name="cropArea"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'cropArea',
                true,
              );
              makeFormDirty();
              setErrorState({
                ...errorState,
                cropArea:
                  event.target.value === '' || +event.target.value === 0,
              });
              setShouldSubmitWork(true);
            }}
            minWidth="2rem"
            width="5rem"
            predefinedUnit={
              findObjectWithKey(cropAndLandUse, CROP_AREA).CROP_AREA[0]
                ?.uomNameDisplay
            }
            hasPredefinedUnit
            marginBottom
            category="long-numbers-field"
          />
        </FieldSectionWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <DropDown
          isDisabled={isFieldDisabled || disableAllFieldActions}
          headerColor={FARM_FIELD_LABEL_COLOR}
          defaultDropdownColor={FORM_BG_BORDER}
          minWidth="13rem"
          customPlaceholderColor={COOL_GRAY}
          labelMarginTop="0.5rem"
          labelMarginBottom="0.25rem"
          hasNoBottomMargin={true}
          label={MODAL_CONTENT.management_type}
          value={checkTernaryCondition(
            formValue?.cropPlannedManagementTypeId === '' ||
              managementTypeDropDown[
                formValue?.cropPlannedManagementTypeId - 1
              ] == undefined,
            MODAL_CONTENT.select_management_type,
            formValue?.cropPlannedManagementTypeId,
          )}
          onUpdate={() => {
            setShouldSubmitWork(true);
            makeFormDirty();
          }}
          stateValue={formValue?.cropPlannedManagementTypeId}
          setFormFieldValue={customFieldChangeHandler}
          setStateValue={() => {}}
          field_key="cropPlannedManagementTypeId"
          name={MODAL_CONTENT.management_type}
          dropdownlist={managementTypeDropDown}
          dropDownPlaceholder={MODAL_CONTENT.select_management_type}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          error={
            errorState.cropPlannedManagementTypeId ||
            (formValue.cropPlannedManagementTypeId === '' &&
              formValue.isFetchedFromAPI)
          }
          setModalErrorState={setModalErrorState}
          fieldName="cropPlannedManagementTypeId"
          showLabelAsValue={true}
        />
        <FieldSectionWrapper columnGap="1rem">
          <InputField
            headerColor={FARM_FIELD_LABEL_COLOR}
            isDisabled={
              isFallowSelected() ||
              disableAnnualField ||
              isFieldDisabled ||
              disableAllFieldActions
            }
            noGap
            optionalLabelStyle="0.2rem"
            label={MODAL_CONTENT.annualYield}
            category={INPUT_CATEGORY.LONG_NUMBER}
            value={checkTernaryCondition(
              isFallowSelected() || disableAnnualField || isFieldDisabled,
              0,
              formValue?.annualYield,
            )}
            placeholder={MODAL_CONTENT.typeAmount}
            name={MODAL_CONTENT.annualYieldName}
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                MODAL_CONTENT.annualYieldName,
                true,
              );
              makeFormDirty();
              setShouldSubmitWork(true);
              setErrorState((prev) => ({
                ...prev,
                annualYield: event.target.value === '',
              }));
            }}
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            primaryError={
              (errorState.annualYield &&
                !disableAnnualField &&
                !isFallowSelected() &&
                !isFieldDisabled) ||
              (errorState.annualYieldRange &&
                !disableAnnualField &&
                !isFieldDisabled)
            }
            primaryErrorMessage={formatErrorMessage(
              errorState,
              MODAL_CONTENT.annualYieldRange,
              errorState.annualYieldErrorMessage,
            )}
            testId={'annual-yield-test-id'}
            optionalOpacity={1}
            textDisabledColor={BLACK}
          />
          <DropDown
            defaultDropdownColor={FORM_BG_BORDER}
            headerColor={FARM_FIELD_LABEL_COLOR}
            customPlaceholderColor={checkTernaryCondition(
              isFallowSelected(),
              DISABLED_TEXT_COLOR,
              COOL_GRAY,
            )}
            isDisabled={
              isEmpty(formValue?.annualYield) ||
              disableAnnualFieldUom ||
              isFallowSelected() ||
              isFieldDisabled ||
              disableAllFieldActions
            }
            label={MODAL_CONTENT.uom}
            value={checkTernaryCondition(
              isEmpty(formValue?.annualYieldUom),
              MODAL_CONTENT.select_type,
              annualYieldDropdown(
                findObjectWithKey(cropAndLandUse, ANNUAL_YIELD).ANNUAL_YIELD,
              )?.find((item) => +item.value === +formValue?.annualYieldUom)
                ?.label,
            )}
            stateValue={formValue?.annualYieldUom}
            setFormFieldValue={customFieldChangeHandler}
            field_key="annualYieldUom"
            setStateValue={() => {}}
            name="annualYieldUom"
            dropdownlist={annualYieldDropdown(
              findObjectWithKey(cropAndLandUse, ANNUAL_YIELD).ANNUAL_YIELD,
            )}
            dropDownPlaceholder={MODAL_CONTENT.select_type}
            height={MODAL_CONTENT.dropdownHeight}
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.FIT_CONTENT}
            fieldName="annualYieldUom"
            onUpdate={(event) => {
              if (event.target.value !== '') {
                setErrorState((prev) => ({ ...prev, cropDepth: false }));
              }
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
          />
          <CropValueInput>
            <CropValueDollar>{MODAL_CONTENT.crop_value_dollar}</CropValueDollar>
            <div>
              <InputField
                headerColor={FARM_FIELD_LABEL_COLOR}
                noGap
                optionalLabelStyle="0.2rem"
                label={MODAL_CONTENT.crop_value_input}
                value={formValue?.cropValue}
                placeholder={MODAL_CONTENT.crop_value_placeholder}
                name="cropValue"
                onUpdate={(event) => {
                  handleTextFieldValueChange(
                    event,
                    event.target.value,
                    'cropValue',
                    true,
                  );
                  setShouldSubmitWork(true);
                  makeFormDirty();
                }}
                minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
                width={MODAL_CONTENT.eightPointSevenFiveRem}
                cropValueLineHeight="1.225rem"
                isOptional
                optionalOpacity={1}
                predefinedUnit={
                  checkTernaryCondition(
                    isEmpty(formValue?.annualYieldUom) ||
                      annualYieldDropdown(
                        findObjectWithKey(cropAndLandUse, ANNUAL_YIELD)
                          .ANNUAL_YIELD,
                      )?.find(
                        (item) => +item.value === +formValue?.annualYieldUom,
                      )?.label === MODAL_CONTENT.PERCENT_HARVEST_EFFICIENCY,
                    MODAL_CONTENT.crop_value_empty,
                    `${MODAL_CONTENT.PER} ${
                      annualYieldDropdown(
                        findObjectWithKey(cropAndLandUse, ANNUAL_YIELD)
                          .ANNUAL_YIELD,
                      )?.find(
                        (item) => +item.value === +formValue?.annualYieldUom,
                      )?.label
                    }`,
                  ).split('/')[0]
                }
                hasPredefinedUnit
                isDisabled={
                  isFallowSelected() ||
                  isFieldDisabled ||
                  disableAllFieldActions
                }
                textDisabledColor={BLACK}
                category="number-field"
              />
            </div>
          </CropValueInput>
        </FieldSectionWrapper>
      </SectionWrapper>
      <FieldSectionWrapper columnGap="1.5rem">
        <CustomDatePicker {...datePlantedProps} />
        <CustomDatePicker {...dateHarvestedProps} />
      </FieldSectionWrapper>
      {Object.values(dateExceedError).includes(true) &&
        !isFallowSelected() &&
        !isFieldDisabled &&
        !Object.values(datePlantedSeasonError).includes(true) &&
        !Object.values(dateHarvestedSeasonError).includes(true) && (
          <TextWrapper
            color={RED}
            fontSize="0.75rem"
            data-testid="date_exceed_error">
            {checkTernaryCondition(
              dateExceedError.harvested,
              MODAL_CONTENT.date_harvest_error,
              MODAL_CONTENT.date_plant_error,
            )}
          </TextWrapper>
        )}
      {datePlantedSeasonError.summer && (
        <TextWrapper
          color={RED}
          fontSize="0.75rem"
          data-testid="date_planted_summer">
          {MODAL_CONTENT.date_planted_summer_error.replaceAll(
            '{{current year}}',
            `${+cropYear}`,
          )}
        </TextWrapper>
      )}
      {datePlantedSeasonError.winter && (
        <TextWrapper
          color={RED}
          fontSize="0.75rem"
          data-testid="date_planted_winter">
          {MODAL_CONTENT.date_planted_winter_error
            .replace('{{previous year}}', `${+cropYear - 1}`)
            .replace('{{current year}}', `${+cropYear}`)}
        </TextWrapper>
      )}
      {dateHarvestedSeasonError.summer && (
        <TextWrapper
          color={RED}
          fontSize="0.75rem"
          data-testid="date_harvested_summer">
          {MODAL_CONTENT.date_harvested_summer_error.replaceAll(
            '{{current year}}',
            `${+cropYear}`,
          )}
        </TextWrapper>
      )}
      {dateHarvestedSeasonError.winter && (
        <TextWrapper
          color={RED}
          fontSize="0.75rem"
          data-testid="date_harvested_winter">
          {MODAL_CONTENT.date_harvested_winter_error
            .replace('{{previous year}}', `${+cropYear - 1}`)
            .replace('{{current year}}', `${+cropYear}`)}
        </TextWrapper>
      )}
      <SectionWrapper>
        <FieldSectionWrapper columnGap="1.5rem">
          <InputField
            noGap
            optionalLabelStyle="0.2rem"
            headerColor={FARM_FIELD_LABEL_COLOR}
            label={MODAL_CONTENT.crop_density}
            isOptional
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            value={formValue?.plantingDensity}
            placeholder={MODAL_CONTENT.type_density}
            name="plantingDensity"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'plantingDensity',
                true,
              );
              if (event.target.value === '') {
                setFormValue({
                  ...formValue,
                  plantingDensity: '',
                  plantingDensityUom: '',
                });
                setErrorState((prev) => ({ ...prev, cropDensity: false }));
              }
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
            optionalOpacity={1}
            isDisabled={
              isFallowSelected() || isFieldDisabled || disableAllFieldActions
            }
            textDisabledColor={BLACK}
            category={INPUT_CATEGORY.LONG_NUMBER}
            primaryError={errorState.plantingDensity}
            primaryErrorMessage={formatErrorMessage(
              errorState,
              MODAL_CONTENT.plantingDensityErrorMessage,
              errorState.plantingDensityErrorMessage,
            )}
            data-testid="planting-density"
          />
          <DropDown
            headerColor={FARM_FIELD_LABEL_COLOR}
            defaultDropdownColor={FORM_BG_BORDER}
            label={MODAL_CONTENT.uom}
            value={checkTernaryCondition(
              isEmpty(formValue?.plantingDensityUom),
              MODAL_CONTENT.select_type,
              +formValue?.plantingDensityUom,
            )}
            isDisabled={
              isCashCropSelected() ||
              isCoverCropSelected() ||
              isFallowSelected() ||
              formValue?.plantingDensity === 0 ||
              !formValue?.plantingDensity ||
              isFieldDisabled ||
              disableAllFieldActions
            }
            width={MODAL_CONTENT.sevenPointFiveRem}
            minWidth={MODAL_CONTENT.sixPointTwoFiveRem}
            height={MODAL_CONTENT.dropdownHeight}
            field_key="plantingDensityUom"
            ariaLabel={MODAL_CONTENT.select_type}
            dropDownPlaceholder={MODAL_CONTENT.select_type}
            error={
              +formValue.plantingDensity > 0 &&
              isEmpty(formValue?.plantingDensityUom) &&
              !isFallowSelected() &&
              !isFieldDisabled &&
              formValue?.isFetchedFromAPI
            }
            errorMessage=""
            dropdownlist={cropDensityAndDepthDropdownList(
              findObjectWithKey(cropAndLandUse, PLANTING_DENSITY)
                .PLANTING_DENSITY,
            )}
            isNotOutlined={false}
            setFormFieldValue={customFieldChangeHandler}
            setStateValue={() => {
              /* do nothing here */
            }}
            showLabelAsValue={true}
            fieldName="plantingDensityUom"
            onUpdate={(event) => {
              if (event.target.value !== '') {
                setErrorState((prev) => ({ ...prev, cropDensity: false }));
              }
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
          />
        </FieldSectionWrapper>
        <FieldSectionWrapper columnGap="1.5rem">
          <InputField
            noGap
            headerColor={FARM_FIELD_LABEL_COLOR}
            optionalLabelStyle="0.2rem"
            label={MODAL_CONTENT.crop_depth}
            isOptional
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            value={formValue?.cropDepth}
            placeholder={MODAL_CONTENT.type_depth}
            category="long-numbers-field"
            name="cropDepth"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'cropDepth',
                true,
              );
              if (event.target.value === '') {
                setFormValue({ ...formValue, cropDepth: '', cropDepthUom: '' });
                setErrorState((prev) => ({ ...prev, cropDepth: false }));
              }
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
            optionalOpacity={1}
            isDisabled={
              isFallowSelected() || isFieldDisabled || disableAllFieldActions
            }
            textDisabledColor={BLACK}
          />
          <DropDown
            headerColor={FARM_FIELD_LABEL_COLOR}
            defaultDropdownColor={FORM_BG_BORDER}
            label={MODAL_CONTENT.uom}
            value={checkTernaryCondition(
              isEmpty(formValue?.cropDepthUom),
              MODAL_CONTENT.select_type,
              +formValue?.cropDepthUom,
            )}
            isDisabled={
              returnTrueWhenValueIsNullOrEmpty(formValue?.cropDepth) ||
              isFallowSelected() ||
              isFieldDisabled ||
              disableAllFieldActions
            }
            width={MODAL_CONTENT.sevenPointFiveRem}
            minWidth={MODAL_CONTENT.sixPointTwoFiveRem}
            height={MODAL_CONTENT.dropdownHeight}
            field_key="cropDepthUom"
            ariaLabel={MODAL_CONTENT.select_type}
            dropDownPlaceholder={MODAL_CONTENT.select_type}
            error={
              +formValue.cropDepth > 0 &&
              isEmpty(formValue?.cropDepthUom) &&
              !isFallowSelected() &&
              !isFieldDisabled &&
              formValue?.isFetchedFromAPI
            }
            errorMessage=""
            dropdownlist={cropDensityAndDepthDropdownList(
              findObjectWithKey(cropAndLandUse, SEEDING_DEPTH).SEEDING_DEPTH,
            )}
            isNotOutlined={false}
            setFormFieldValue={customFieldChangeHandler}
            setStateValue={() => {}}
            showLabelAsValue={true}
            fieldName="cropDepthUom"
            onUpdate={(event) => {
              if (event.target.value !== '') {
                setErrorState((prev) => ({ ...prev, cropDepth: false }));
              }
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
          />
        </FieldSectionWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <FieldSectionWrapper marginBottom="0">
          <DropDown
            isDisabled={isFieldDisabled || disableAllFieldActions}
            {...agricultureResiduesDropdownProps}
          />
        </FieldSectionWrapper>
        {residueTypeDropdown.filter(
          (item) => item.value === formValue?.agricultureResiduesManaged,
        )[0]?.label !== 'None' && (
          <InputField
            isDisabled={
              isEmpty(formValue?.agricultureResiduesManaged) ||
              isFieldDisabled ||
              disableAllFieldActions
            }
            headerColor={FARM_FIELD_LABEL_COLOR}
            label={MODAL_CONTENT.crop_residue_removed}
            placeholder={'%'}
            value={formValue?.cropResidueRemoved}
            minWidth="11.25rem"
            width="8.75rem"
            name="cropResidueRemoved"
            required
            onUpdate={(event) => {
              const value = event?.target.value;
              if (
                value === '' ||
                (value !== '.' && parseFloat(value) <= 100 && value !== '100.')
              ) {
                setFormValue({
                  ...formValue,
                  cropResidueRemoved: value,
                });
                setModalErrorState(event.target.value, 'cropResidueRemoved');
                makeFormDirty();
              }
              setShouldSubmitWork(true);
            }}
            primaryError={
              errorState.cropResidueRemoved && formValue?.isFetchedFromAPI
            }
            hasPredefinedUnit={true}
            predefinedUnit="%"
            labelMarginTop="0.75rem"
            labelMarginBottom="0.25rem"
            rightSideLabelFormLabelMargin="0rem"
            customInputSx={InputSx}
            category="long-numbers-field"
          />
        )}
      </SectionWrapper>
      <SectionWrapper direction="column" alignment="start" gap="0rem">
        <SectionWrapper direction="column" alignment="start" gap="0rem">
          <SectionWrapper direction="column" alignment="start" gap="0.5rem">
            {formValue?.irrigationList?.map((item, index) => {
              const keyVal = index;
              const hasOtherSelected = formValue.irrigationList.find(
                (item) => +item.irrigationType > 0,
              );
              const idList = formValue.irrigationList.map(
                (item) => item.irrigationType,
              );
              const filteredIrrigationIdList = checkTernaryCondition(
                hasOtherSelected,
                [...idList, irrigationNoneId],
                idList,
              );
              return (
                <IrrigationModal
                  isFetchedFromAPI={formValue.isFetchedFromAPI}
                  key={keyVal}
                  modalOpen={{
                    flag: true,
                    id: item.id,
                    data: item,
                    currentRowId: item.id,
                  }}
                  parentId={formValue.id}
                  totalFieldArea={totalFieldArea}
                  isSubmitClicked={isSubmitClicked}
                  setIsSubmitClicked={setIsSubmitClicked}
                  submitHandler={practiceSubmitHandler}
                  setShouldSubmitWork={setShouldSubmitWork}
                  handlePracticeTypeDelete={handlePracticeTypeDelete}
                  showIrrigationDeleteIcon={formValue.irrigationList.length > 1}
                  existingIrrigationIds={filteredIrrigationIdList}
                  makeFormDirty={makeFormDirty}
                  hasOnlyOneItem={formValue?.irrigationList.length === 1}
                  disableAllFieldActions={disableAllFieldActions}
                />
              );
            })}
          </SectionWrapper>
          {!isFieldDisabled && (
            <Button
              disabled={
                formValue?.irrigationList.find(
                  (item) => item.irrigationType === 0,
                ) ||
                (formValue?.irrigationList.length === 1 &&
                  formValue?.irrigationList[0]?.irrigationType === '') ||
                irrigationMethodDropDown.every(
                  (dropdownItem) =>
                    dropdownItem.value === irrigationNoneId ||
                    formValue?.irrigationList.some(
                      (item) => item.irrigationType === dropdownItem.value,
                    ),
                ) ||
                disableAllFieldActions
              }
              id="basic-button"
              aria-haspopup="true"
              variant={'contained'}
              size={'medium'}
              disableElevation
              sx={addBtnSx(
                false,
                formValue?.irrigationList.find(
                  (item) => item.irrigationType === 0,
                ),
              )}
              setShouldSubmitWork={setShouldSubmitWork}
              onClick={() => {
                setShouldSubmitWork(true);
                setFormValue({
                  ...formValue,
                  irrigationList: [
                    ...formValue.irrigationList,
                    {
                      irrigationRate: '',
                      irrigationType: '',
                      uom: '',
                      id: uniqueId(),
                    },
                  ],
                });
              }}>
              {MODAL_CONTENT.irrigation_button}
            </Button>
          )}
        </SectionWrapper>
        <SectionWrapper direction="column" alignment="start" gap="0.9rem">
          <SectionWrapper direction="column" alignment="start" gap="0rem">
            {formValue?.soilAmendmentList?.map((item, index) => {
              const keyVal = index;
              return (
                <SoilAmendmentsModal
                  isFetchedFromAPI={formValue.isFetchedFromAPI}
                  parentId={formValue.id}
                  key={keyVal}
                  modalOpen={{
                    flag: true,
                    id: item.id,
                    data: item,
                    currentRowId: item.id,
                  }}
                  setShouldSubmitWork={setShouldSubmitWork}
                  submitHandler={practiceSubmitHandler}
                  handlePracticeTypeDelete={handlePracticeTypeDelete}
                  existingSoilAmendmentIds={formValue.soilAmendmentList.map(
                    (item) => item.soilAmendmentsType,
                  )}
                  makeFormDirty={makeFormDirty}
                />
              );
            })}
          </SectionWrapper>
          {!isFieldDisabled && (
            <div data-testid="soil_amendment_button">
              <Button
                id="basic-button"
                aria-haspopup="true"
                variant={'contained'}
                size={'medium'}
                disableElevation
                sx={addBtnSx()}
                disabled={
                  soilAmendmentsDropdown.every((dropdownItem) =>
                    formValue.soilAmendmentList.some(
                      (item) => item.soilAmendmentsType === dropdownItem.value,
                    ),
                  ) || disableAllFieldActions
                }
                onClick={() => {
                  setShouldSubmitWork(true);
                  setFormValue({
                    ...formValue,
                    soilAmendmentList: [
                      ...formValue.soilAmendmentList,
                      {
                        sampleId: 0,
                        soilAmendmentsType: '',
                        amountApplied: '',
                        unitOfMeasure: '',
                        id: uniqueId(),
                      },
                    ],
                  });
                }}>
                {MODAL_CONTENT.soil_amendment_button}
              </Button>
            </div>
          )}
        </SectionWrapper>
      </SectionWrapper>
    </CropMainWrapper>
  );
};

CropTypeModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  tabWiseSubmitHandler: PropTypes.func,
  totalFieldArea: PropTypes.number,
  setYearWiseCashCropList: PropTypes.func,
  yearWiseCashCropList: PropTypes.array,
  setActivityList: PropTypes.func,
  hasTestDefaultDate: PropTypes.bool,
  cropYear: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object,
  setIsDeleteModalOpen: PropTypes.func,
  isSubmitClicked: PropTypes.bool,
  setIsSubmitClicked: PropTypes.func,
  makeFormDirty: PropTypes.func,
  copiedDataPresent: PropTypes.bool,
  disableAllFieldActions: PropTypes.bool,
};
export default CropTypeModal;
