import { pathName } from 'Routes/routes.const';
import axios from 'axios';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import {
  FINAL_PAGE_CONTENT,
  LANDING_PAGE_CONTENT,
} from 'pages/ParticipantDataCollection/pages/ParticipantPages.content';
import { useContext, useState } from 'react';
import {
  PARTICIPANT_PROFILE,
  UPDATE_PARTICIPANT_ENROLLMENT_STATUS,
} from 'urls';
import { checkTernaryCondition } from 'utils/helper';

export const useParticipantEnrollmentDetails = () => {
  const [pageDetails, setPageDetails] = useState(null);
  const [apiError, setApiError] = useState('');
  const [apiMessage, setApiMessage] = useState('');
  const [apiSuccessStatus, setApiSuccessStatus] = useState(false);
  const [supportEmail, setSupportEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataEmptyError, setDataEmptyError] = useState(false);
  const { clientConfigs } = useContext(userAuthRolesContext);
  const [fetchedDataEmpty, setFetchedDataEmpty] = useState(false);
  const fetchPageDetails = (projectId, participantId, enrollmentType) => {
    setLoading(true);
    axios
      .get(
        `${PARTICIPANT_PROFILE}?enrollmentType=${enrollmentType}&projectId=${checkTernaryCondition(
          dataEmptyError || fetchedDataEmpty,
          -1,
          projectId,
        )}&participantId=${checkTernaryCondition(
          dataEmptyError || fetchedDataEmpty,
          -1,
          participantId,
        )}`,
      )
      .then((response) => {
        const data = response.data;
        setPageDetails({
          participantFirstName: data.firstName,
          projectName: data.projectName,
          enrollmentStatus: data.participantProjectStatus,
          participantProfileStatus: data.participantStatus,
          farmDetails: data.farmDetails,
          participantLastName: data.lastName,
          participantEmail: data.email,
          baselineEnrollmentStatus: data.baselineEnrollmentStatus,
          participantCreatedDate: data.participantCreatedDate,
          contributorEnrollmentStatus: null, // Hard code value for now
          activityReportingStatus: data.activityReportingStatus,
        });
      })
      .catch((error) => {
        if (
          error?.response?.status ===
            LANDING_PAGE_CONTENT.api_fail_status_401 ||
          error?.response?.status === LANDING_PAGE_CONTENT.api_fail_status_417
        ) {
          setApiSuccessStatus(error?.response?.status);
          setApiMessage(error?.response?.data?.message);
          setApiError(error?.response?.data?.errorCode);
          setSupportEmail(error?.response?.data?.['support-email']);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const finishEnrollmentHandler = (
    participantId,
    projectId,
    enrollmentType,
    enrollmentIds = [],
    currentEnrollmentId,
    enrollmentUrlType,
    projectCycleId,
  ) => {
    setLoading(true);
    const reqBody = {
      participantId: Number(participantId),
      internalProjectId: Number(projectId),
      enrollmentType: enrollmentType,
      enrollmentIds: enrollmentIds,
      currentEnrollmentInstanceId: currentEnrollmentId,
      projectCycleId: Number(projectCycleId),
    };

    axios
      .put(UPDATE_PARTICIPANT_ENROLLMENT_STATUS, reqBody)
      .then(() => {
        fetchPageDetails(projectId, participantId, enrollmentUrlType);
      })
      .then()
      .catch(() => {
        displayToast(
          FINAL_PAGE_CONTENT.ERROR,
          FINAL_PAGE_CONTENT.ENROLLMENT_FAILED_ERROR_MESSAGE,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Functions for generic moduleName (enrollment, baseline, activity)
  const getClosedPageStatus = (moduleName, navigateHandler) => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        if (pageDetails !== null) {
          if (
            pageDetails.enrollmentStatus ===
            LANDING_PAGE_CONTENT.enrollmentProgressStatus
          ) {
            navigateHandler();
          }
          return (
            pageDetails.enrollmentStatus ===
            LANDING_PAGE_CONTENT.enrollmentEnrolledStatus
          );
        }
        break;
      case LANDING_PAGE_CONTENT.baselineModuleName:
        if (
          pageDetails?.baselineEnrollmentStatus ===
          LANDING_PAGE_CONTENT.enrollmentProgressStatus
        ) {
          navigateHandler();
        }
        return (
          pageDetails?.baselineEnrollmentStatus ===
          LANDING_PAGE_CONTENT.enrollmentEnrolledStatus
        );

      case LANDING_PAGE_CONTENT.activityModuleName:
        if (
          pageDetails?.activityReportingStatus ===
          LANDING_PAGE_CONTENT.activityProgressStatus
        ) {
          navigateHandler();
        }
        return (
          pageDetails?.activityReportingStatus ===
          LANDING_PAGE_CONTENT.enrollmentEnrolledStatus
        );
      case LANDING_PAGE_CONTENT.contributorModuleName:
        if (
          pageDetails?.contributorEnrollmentStatus ===
          LANDING_PAGE_CONTENT.enrollmentProgressStatus
        ) {
          navigateHandler();
        }
        return (
          pageDetails?.contributorEnrollmentStatus ===
          LANDING_PAGE_CONTENT.enrollmentEnrolledStatus
        );
      default:
        return '';
    }
  };

  const getPageContent = (moduleName) => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return LANDING_PAGE_CONTENT.content;
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return LANDING_PAGE_CONTENT.baseline_landing_content;
      case LANDING_PAGE_CONTENT.activityModuleName:
        return LANDING_PAGE_CONTENT.activity_landing_content;
      case LANDING_PAGE_CONTENT.contributorModuleName:
        return LANDING_PAGE_CONTENT.content;
      default:
        return '';
    }
  };

  const getBeginBtnLabel = (moduleName) => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return LANDING_PAGE_CONTENT.begin_enrolment_btn_label;
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return LANDING_PAGE_CONTENT.baseline_begin_btn;
      case LANDING_PAGE_CONTENT.activityModuleName:
        return LANDING_PAGE_CONTENT.activity_begin_btn;
      case LANDING_PAGE_CONTENT.contributorModuleName:
        return LANDING_PAGE_CONTENT.begin_enrolment_btn_label;
      default:
        return '';
    }
  };

  const getPathName = (moduleName) => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return pathName.participant.participantDataCollection;
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return pathName.participant.participantFieldBaseline;
      case LANDING_PAGE_CONTENT.activityModuleName:
        return pathName.participant.participantFieldActivity;
      case LANDING_PAGE_CONTENT.contributorModuleName:
        return pathName.participant.participantDataCollection;
      default:
        return '';
    }
  };

  const getCompletedHeading = (
    moduleName,
    getBaselineYearStart,
    getBaselineYearEnd,
    getYear,
  ) => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return LANDING_PAGE_CONTENT.enrollment_closed_heading;
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return LANDING_PAGE_CONTENT.baseline_completed_heading
          .replace('{{year1}}', getBaselineYearStart)
          .replace('{{year2}}', getBaselineYearEnd);
      case LANDING_PAGE_CONTENT.activityModuleName:
        return LANDING_PAGE_CONTENT.activity_completed_heading.replace(
          '{{year}}',
          getYear,
        );
      case LANDING_PAGE_CONTENT.contributorModuleName:
        return LANDING_PAGE_CONTENT.enrollment_closed_heading;
      default:
        return '';
    }
  };

  const getCompletedContent = (
    moduleName,
    getBaselineYearStart,
    getBaselineYearEnd,
    getYear,
  ) => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return LANDING_PAGE_CONTENT.enrollment_closed_content
          .replace('{{project_name}}', pageDetails?.projectName)
          .replace('{{support_email}}', clientConfigs[0]?.value);
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return LANDING_PAGE_CONTENT.baseline_completed_content
          .replace('{{year1}}', getBaselineYearStart)
          .replace('{{year2}}', getBaselineYearEnd)
          .replace('{{project_name}}', pageDetails?.projectName)
          .replace('{{support_email}}', clientConfigs[0]?.value);
      case LANDING_PAGE_CONTENT.activityModuleName:
        return LANDING_PAGE_CONTENT.activity_completed_content
          .replace('{{year}}', getYear)
          .replace('{{project_name}}', pageDetails?.projectName)
          .replace('{{support_email}}', clientConfigs[0]?.value);
      case LANDING_PAGE_CONTENT.contributorModuleName:
        return LANDING_PAGE_CONTENT.enrollment_closed_content
          .replace('{{project_name}}', pageDetails?.projectName)
          .replace('{{support_email}}', clientConfigs[0]?.value);
      default:
        return '';
    }
  };

  return {
    pageDetails,
    fetchPageDetails,
    apiError,
    supportEmail,
    apiSuccessStatus,
    apiMessage,
    loading,
    dataEmptyError,
    setDataEmptyError,
    finishEnrollmentHandler,
    getClosedPageStatus,
    fetchedDataEmpty,
    setFetchedDataEmpty,
    getPageContent,
    getBeginBtnLabel,
    getPathName,
    getCompletedHeading,
    getCompletedContent,
  };
};
