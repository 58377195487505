export const MODAL_CONTENT = {
  modal_heading: 'Contact support',
  ISSUE_TYPE_LABEL: 'Please identify the type of issue you are encountering.​',
  ISSUE_TYPE_PLACEHOLDER: 'Select issue',
  LEVEL_OF_IMPORTANCE_PLACEHOLDER: 'Select level of importance',
  ISSUE_TYPE_REQUIRED_ERROR: 'A selection for the type of issue is required.',
  LIST_OF_IMPORTANCE_REQUIRED_ERROR:
    'A selection for the level of importance is required.',
  SPECIFIC_ISSUE_TYPE_LABEL: 'Please specify the type of issue.​',
  SPECIFIC_FEEDBACK_TYPE_LABEL: 'Please specify the type of feedback.​',
  SPECIFIC_ISSUE_TYPE_PLACEHOLDER: 'Type issue',
  colon: ': ',
  SPECIFIC_FEEDBACK_TYPE_PLACEHOLDER: 'Type feedback',
  DESCRIBE_ISSUE_LABEL: `Please provide a detailed description of the issue you're encountering.`,
  LEVEL_OF_IMPORTANCE_LABEL: `What is the level of importance?`,
  DESCRIBE_ISSUE_PLACEHOLDER: 'Type here',
  LEVEL_OF_IMPORTANCE_LABEL_HEADING: 'Levels of importance',
  DESCRIBE_ISSUE_TOOLTIP_LABEL_HEADING:
    'Try to describe your issue through the following:',
  DESCRIBE_ISSUE_REQUIRED_ERROR:
    'A description of the issue you experienced is required.',
  SCREENSHOT_UPLOAD_LABEL:
    'If you can, please include screenshots related to the issue.',
  SCREENSHOT_DROP_FILE_LABEL: 'Drag and drop a .jpg or .png image file here',
  OPTIONAL_LABEL: '(Optional)',
  CANCEL_BTN_LABEL: 'Cancel',
  SUBMIT_BTN_LABEL: 'Submit',
  MESSAGE_SENT_LABEL: 'Message sent',
  EMAIL_KEY: 'support-email',
  MESSAGE_SENT_CONTENT:
    'Thank you for your help request. You’ll receive an email from {{support_email}} confirming we received your message. Please expect a response via email within 3 business days. We appreciate your feedback!',
};

export const ISSUE_TYPE_LIST = [
  { label: 'Account Access', value: 'Account Access' },
  { label: 'Data Submission', value: 'Data Submission' },
  { label: 'Navigation', value: 'Navigation' },
  { label: 'Performance', value: 'Performance' },
  { label: 'Enhancement', value: 'Enhancement' },
  { label: 'Feedback', value: 'Feedback' },
  { label: 'Other', value: 'Other' },
];
export const IMPORTANCE_LIST = [
  { label: 'Critical', value: 'Critical' },
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' },
];

export const FILE_UPLOAD_CONFIG = {
  accept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  maxNoOfFiles: 1,
  maxFileSize: 1024 * 1024 * 8, //8MB
  fileNotSupportedErrorText:
    'File isn’t supported. Please upload only a .jpg or .png file.',
};

export const formInitialState = {
  issueType: '',
  specificIssueType: '',
  specificFeedbackType: '',
  levelOfImportance: '',
  images: [],
  description: '',
};

export const fieldNames = {
  issueType: 'issueType',
  specificIssueType: 'specificIssueType',
  specificFeedbackType: 'specificFeedbackType',
  levelOfImportance: 'levelOfImportance',
  images: 'images',
  description: 'description',
};

export const describe_issue_tooltip_label_content = [
  { data: 'What were you trying to do when the issue occurred?' },
  { data: 'What steps led to the issue?' },
  { data: 'Are you able to reproduce the issue, and if so, how?' },
  {
    data: 'Did you receive any error messages? If yes, please provide the exact text or a screenshot if possible.',
  },
  {
    data: 'How is this issue affecting your work or usage of our application?',
  },
];

export const level_of_importance_tooltip_label_content = [
  {
    level: 'Critical',
    data: 'Issue causing significant business disruption. Software is inaccessible or non-functioning. Choose this if you cant use the software at all.',
  },
  {
    level: 'High',
    data: 'Major functionality or performance issues. Choose this if the software works, but performance or functionality is seriously impacted.',
  },
  {
    level: 'Medium',
    data: 'Noticeable performance issues, but software continues to be functional and accessible. Choose this if the software is slower than usual or some features are not working properly.',
  },
  {
    level: 'Low',
    data: 'Minor errors affecting ease of operation or display of information, or if you need information or assistance on the softwares capabilities, configurations, or operation. Choose this for non-urgent issues or questions.',
  },
];

export const errorInitialState = {
  issueTypeError: false,
  specificIssueTypeError: false,
  specificFeedbackTypeError: false,
  descriptionError: false,
  imageError: false,
  levelOfImportanceError: false,
};

export const HELP_BUTTON_CONTENT = {
  USER_GUIDE: 'User guide',
  FAQ: 'FAQs',
  CONTACT_SUPPORT: 'Contact support',
  HELP_TEXT: 'help',
  VIDEO_TUTORIALS: 'Video tutorials',
};
